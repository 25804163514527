<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <strong>Transfer:</strong><br/>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon3">To:</span>
              <input type="text" v-model="transferAddress" class="form-control" placeholder="Address: 0x..." />
              <button class="btn btn-primary" @click="transferNft()">{{ transferButtonLabel }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transferAddress: null,
      transferButtonLabel: "Transfer",
    }
  },
  props: ['id'],
  methods: {
    transferNft: function() {
      this.transferButtonLabel = 'Sending transaction...';
      this.$store.getters.freshNftContract.transferFrom(
          this.$store.state.address,
          this.transferAddress,
          this.id
      ).then(async (tx) => {
          await tx.wait();
          window.location.reload();
      });
    },
  }
}
</script>