<template>
  <section class="content-section" id="signup">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5" v-if="!catalog">My traits</h2>
          <h2 class="text-white mb-5" v-if="catalog">Catalog</h2>
          <!--button class="btn btn-primary" @click="useCatalog()">View Catalog</button-->
          <!--button class="btn btn-primary" @click="useTraits()">View my traits</button-->
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row" v-if="!$store.state.connected">
        <div class="text-center">
          <p>Please connect to {{ $store.getters.config.chainName }}.</p>
          <button class="btn btn-primary" v-on:click="$store.dispatch('connectWallet')">Connect wallet</button>
        </div>
      </div>

      <template v-if="$store.state.connected && $store.state.wallet.length === 0">
        <div class="text-center">
          <button class="btn btn-primary" v-on:click="$store.dispatch('loadWallet')">Load wallet</button>
        </div>
      </template>

      <div class="row" v-if="$store.state.connected">
        <template v-if="selectedTrait">
          <div class="col-3">
            <img v-bind:src="selectedTrait.metadata.image" class="nft img-fluid">
          </div>
          <div class="col-9">
            <h2>{{ selectedTrait.metadata.name }}</h2>
            Slot: {{ selectedTrait.slot }} - {{ $store.getters.config.powerIcon }} {{ selectedTrait.metadata.power }}
            <Transfer
                v-if="catalog == false"
                v-bind:ca="$store.getters.config.nftCA"
                v-bind:id="selectedTrait.id"></Transfer>
          </div>
        </template>
        <div class="col-12 text-center trait-types">
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Head' ? 'selected':'')" @click="filterShelves('Head')">Head</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Goggles' ? 'selected':'')" @click="filterShelves('Goggles')">Goggles</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Back' ? 'selected':'')" @click="filterShelves('Back')">Back</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Face' ? 'selected':'')" @click="filterShelves('Face')">Face</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Mouth' ? 'selected':'')" @click="filterShelves('Mouth')">Mouth</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Neck' ? 'selected':'')" @click="filterShelves('Neck')">Neck</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Hands' ? 'selected':'')" @click="filterShelves('Hands')">Hands</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Clothing' ? 'selected':'')" @click="filterShelves('Clothing')">Clothing</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Serum' ? 'selected':'')" @click="filterShelves('Serum')">Serum</button> &nbsp;
          <button v-bind:class="'btn btn-outline-primary ' + (filter == 'Spray' ? 'selected':'')" @click="filterShelves('Spray')">Spray</button> &nbsp;
        </div>
        <template v-for="nft in filteredNfts" :key="nft">
          <div class="col-6 col-lg-1 col-md-3 col-sm-6 shelve-tooltip rat clickable" v-if="nft.metadata.slot" @click="selectTrait(nft)">
            <img v-bind:src="nft.image" v-bind:class="'img-fluid nft ' + (selectedTrait && selectedTrait.id == nft.id?'selected':'')" /><br/>
            <div v-if="nft.metadata.trait_id === 165 && catalog == false" class="top-left" @click="window.location = '/draw/' + nft.id">✏️</div>
            <div class="shelve-tooltiptext">
              <strong>{{ nft.metadata.name }}</strong><br/>
              {{ nft.slot }} - {{ $store.getters.config.powerIcon }} {{ nft.metadata.power }}
            </div>
          </div>
        </template>
        <div v-if="filteredNfts.length === 0">
          No traits found.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Transfer from "@/components/Rat/Transfer";
export default {
  components: {
    Transfer
  },
  name: 'App',
  data() {
    return {
      nfts: [],
      filteredNfts: [],
      selectedTrait: null,
      catalog: false,
      items: [],
    }
  },
  watch: {
    '$store.state.loaded': function() {
      this.filterShelves();
    },
    '$store.state.wallet': function() {
      this.filterShelves();
    },
    '$store.state.catalog': function() {
      if(this.catalog) {
        this.filterShelves();
      }
    },
    '$store.state.address': function() {
      this.$store.dispatch('loadWallet');
    }
  },
  mounted() {
    //this.$store.dispatch('loadWallet');
    this.filterShelves();
  },
  methods: {
    useCatalog: function () {
      this.filter = null;
      this.catalog = true;
      this.$store.dispatch('loadCatalog');
      this.filterShelves();
    },
    useTraits: function() {
      this.filter = null;
      this.catalog = false;
      this.filterShelves();
    },
    selectTrait: function(nft) {
      this.selectedTrait = nft;
    },
    filterShelves: function(filter) {
      if(this.filter === filter) {
        this.filter = null;
      } else {
        this.filter = filter;
      }
      this.filteredNfts = [];
      if(this.catalog) {
        for(let i in this.$store.state.catalog) {
          let nftData = this.$store.state.catalog[i];
          if(filter == null || nftData.slot === filter) {
            this.filteredNfts.push(nftData);
          }
        }
      } else {
        for(let i in this.$store.state.wallet) {
          let nftData = this.$store.state.nfts[this.$store.state.wallet[i]];
          if(filter == null || nftData.slot === filter) {
            this.filteredNfts.push(nftData);
          }
        }
      }
    }
  }
}
</script>